import { CheckIcon, ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/solid"
import { toast } from "react-hot-toast"

export default function leadsNotifyCreated() {
  toast.custom(
    <div
      className="flex w-[270px] items-center justify-center gap-2 rounded-md bg-[#D3F4D3] 
      px-6 py-4 text-dark-gray shadow-md"
    >
      <div className="flex items-center">
        <span className="pr-2">
          <CheckIcon className="h-4 w-4 font-bold text-[#0CA750]" />
        </span>
        Created
      </div>
    </div>
  )
}

export const leadsNotifyUpdated = () =>
  toast.custom(
    <div
      className="flex w-[270px] items-center justify-center gap-2 rounded-md bg-[#D3F4D3] 
      px-6 py-4 text-dark-gray shadow-md"
    >
      <div className="flex items-center">
        <span className="pr-2">
          <CheckIcon className="h-4 w-4 font-bold text-[#0CA750]" />
        </span>
        Updated
      </div>
    </div>
  )

export const leadsNotifyRollback = () =>
  toast.custom(
    <div
      className="flex w-[270px] items-center justify-center gap-2 rounded-md bg-[#D3F4D3] 
      px-6 py-4 text-dark-gray shadow-md"
    >
      <div className="flex items-center">
        <span className="pr-2">
          <CheckIcon className="h-4 w-4 font-bold text-[#0CA750]" />
        </span>
        Rollback
      </div>
    </div>
  )

export const leadsNotifyDeleted = () =>
  toast.custom(
    <div
      className="flex w-[270px] items-center justify-between gap-2 rounded-md bg-[#FCEDE9] 
      px-4 py-4 text-dark-gray shadow-md"
    >
      <div className="flex items-center">
        <span className="pr-2 text-red-600">
          <ExclamationTriangleIcon className="h-4 w-4" />
        </span>
        Deleted Successfully
      </div>
      <button>Undo</button>
    </div>
  )

export const leadsNotifyQualify = () =>
  toast.custom(
    <div
      className="flex w-[270px] items-center justify-center gap-2 rounded-md bg-[#D3F4D3] 
      px-6 py-4 text-dark-gray shadow-md"
    >
      <div className="flex items-center">
        <span className="pr-2">
          <CheckIcon className="h-4 w-4 font-bold text-[#0CA750]" />
        </span>
        Qualified
      </div>
    </div>
  )

export const leadsNotifyDisqualify = () =>
  toast.custom(
    <div
      className="flex w-[270px] items-center justify-center gap-2 rounded-md bg-[#FCEDE9] 
      px-4 py-4 text-dark-gray shadow-md"
    >
      <div className="flex items-center">
        <span className="pr-2 text-red-600">
          <XMarkIcon className="h-4 w-4" />
        </span>
        Disqualifed
      </div>
    </div>
  )
