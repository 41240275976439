import { ReactNode, createContext, useState } from "react"

type ConfirmState = {
  prompt: string
  isOpen: boolean
  proceed: any
  cancel: any
}
type ConfirmContextType = [ConfirmState, React.Dispatch<React.SetStateAction<ConfirmState>>]

export const ConfirmContext = createContext<ConfirmContextType>(null!)

const ConfirmContextProvider = ({ children }: { children: ReactNode }) => {
  const [confirm, setConfirm] = useState<ConfirmState>({
    prompt: "",
    isOpen: false,
    proceed: () => {},
    cancel: () => {},
  })

  return <ConfirmContext.Provider value={[confirm, setConfirm]}>{children}</ConfirmContext.Provider>
}

export default ConfirmContextProvider
