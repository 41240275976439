import { create } from "zustand"
import { persist, createJSONStorage } from "zustand/middleware"

type Auth = {
  token: string
  tokenCreatedAt: Date
  setToken: (token: string) => void
  setTokenCreatedAt: (token: Date) => void
  removeToken: () => void
  user: object
  setUser: (user: object) => void
  removeUser: () => void
  reset: () => void
}

export const useAuthStore = create<Auth>()(
  persist(
    (set) => ({
      token: "",
      user: {},
      tokenCreatedAt: new Date("2024-02-01"),
      setToken: (token) => set((state) => ({ token: token })),
      setTokenCreatedAt: (token) => set((state) => ({ tokenCreatedAt: token })),
      removeToken: () => set({ token: "" }),
      setUser: (user) => set((state) => ({ user: user })),
      removeUser: () => set({ user: {} }),
      reset: () => {
        useAuthStore.persist.clearStorage()
        set(() => ({ user: {}, token: "" }))
      },
    }),
    {
      name: "global-auth-2024",
      storage: createJSONStorage(() => localStorage),
    }
  )
)

export default useAuthStore
