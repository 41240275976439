function XCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path d="M7.68703 1.31297C5.93641 -0.437656 3.06359 -0.437656 1.31297 1.31297C-0.437656 3.06359 -0.437656 5.93641 1.31297 7.68703C3.06359 9.43766 5.89152 9.43766 7.64214 7.68703C9.39277 5.93641 9.43766 3.06359 7.68703 1.31297ZM5.75686 6.38529L4.5 5.12843L3.24314 6.38529L2.61471 5.75686L3.87157 4.5L2.61471 3.24314L3.24314 2.61471L4.5 3.87157L5.75686 2.61471L6.38529 3.24314L5.12843 4.5L6.38529 5.75686L5.75686 6.38529Z" />
    </svg>
  )
}

export default XCircle
