function ArrowTopRightOnSquareIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M2.11111 19C1.53056 19 1.03339 18.7935 0.619611 18.3804C0.206537 17.9666 0 17.4694 0 16.8889V2.11111C0 1.53056 0.206537 1.03339 0.619611 0.619611C1.03339 0.206537 1.53056 0 2.11111 0H8.44444C8.74352 0 8.99439 0.100981 9.19706 0.302944C9.39902 0.505611 9.5 0.756482 9.5 1.05556C9.5 1.35463 9.39902 1.60515 9.19706 1.80711C8.99439 2.00978 8.74352 2.11111 8.44444 2.11111H2.11111V16.8889H16.8889V10.5556C16.8889 10.2565 16.9902 10.0056 17.1929 9.80294C17.3949 9.60098 17.6454 9.5 17.9444 9.5C18.2435 9.5 18.494 9.60098 18.696 9.80294C18.8987 10.0056 19 10.2565 19 10.5556V16.8889C19 17.4694 18.7935 17.9666 18.3804 18.3804C17.9666 18.7935 17.4694 19 16.8889 19H2.11111ZM6.33333 12.6667C6.13981 12.4731 6.04306 12.2269 6.04306 11.9278C6.04306 11.6287 6.13981 11.3824 6.33333 11.1889L15.4111 2.11111H12.6667C12.3676 2.11111 12.1171 2.00978 11.9151 1.80711C11.7124 1.60515 11.6111 1.35463 11.6111 1.05556C11.6111 0.756482 11.7124 0.505611 11.9151 0.302944C12.1171 0.100981 12.3676 0 12.6667 0H17.9444C18.2435 0 18.494 0.100981 18.696 0.302944C18.8987 0.505611 19 0.756482 19 1.05556V6.33333C19 6.63241 18.8987 6.88293 18.696 7.08489C18.494 7.28756 18.2435 7.38889 17.9444 7.38889C17.6454 7.38889 17.3949 7.28756 17.1929 7.08489C16.9902 6.88293 16.8889 6.63241 16.8889 6.33333V3.58889L7.78472 12.6931C7.5912 12.8866 7.3537 12.9833 7.07222 12.9833C6.79074 12.9833 6.54444 12.8778 6.33333 12.6667Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ArrowTopRightOnSquareIcon
