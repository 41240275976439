function Finance(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path d="M2.68421 12.0789L0 14.7095V7.15789H2.68421M7.15789 10.4326L5.75316 9.23368L4.47368 10.4147V3.57895H7.15789M11.6316 8.94737L8.94737 11.6316V0H11.6316M14.1458 8.77737L12.5263 7.15789H17V11.6316L15.3984 10.03L8.94737 16.4274L5.84263 13.7253L2.46053 17H0L5.78895 11.3274L8.94737 13.9937" />
    </svg>
  )
}

export default Finance
