function Trash(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path d="M1.5 9C1.225 9 0.989667 8.90217 0.794 8.7065C0.598 8.5105 0.5 8.275 0.5 8V1.5C0.358333 1.5 0.2395 1.45217 0.1435 1.3565C0.0478334 1.2605 0 1.14167 0 1C0 0.858333 0.0478334 0.7395 0.1435 0.6435C0.2395 0.547833 0.358333 0.5 0.5 0.5H2.5C2.5 0.358333 2.548 0.2395 2.644 0.1435C2.73967 0.0478333 2.85833 0 3 0H5C5.14167 0 5.2605 0.0478333 5.3565 0.1435C5.45217 0.2395 5.5 0.358333 5.5 0.5H7.5C7.64167 0.5 7.76033 0.547833 7.856 0.6435C7.952 0.7395 8 0.858333 8 1C8 1.14167 7.952 1.2605 7.856 1.3565C7.76033 1.45217 7.64167 1.5 7.5 1.5V8C7.5 8.275 7.40217 8.5105 7.2065 8.7065C7.0105 8.90217 6.775 9 6.5 9H1.5ZM1.5 1.5V8H6.5V1.5H1.5ZM2.5 6.5C2.5 6.64167 2.548 6.76033 2.644 6.856C2.73967 6.952 2.85833 7 3 7C3.14167 7 3.2605 6.952 3.3565 6.856C3.45217 6.76033 3.5 6.64167 3.5 6.5V3C3.5 2.85833 3.45217 2.7395 3.3565 2.6435C3.2605 2.54783 3.14167 2.5 3 2.5C2.85833 2.5 2.73967 2.54783 2.644 2.6435C2.548 2.7395 2.5 2.85833 2.5 3V6.5ZM4.5 6.5C4.5 6.64167 4.548 6.76033 4.644 6.856C4.73967 6.952 4.85833 7 5 7C5.14167 7 5.2605 6.952 5.3565 6.856C5.45217 6.76033 5.5 6.64167 5.5 6.5V3C5.5 2.85833 5.45217 2.7395 5.3565 2.6435C5.2605 2.54783 5.14167 2.5 5 2.5C4.85833 2.5 4.73967 2.54783 4.644 2.6435C4.548 2.7395 4.5 2.85833 4.5 3V6.5Z" />
    </svg>
  )
}

export function TrashSolid(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M2.25 14C1.8375 14 1.4845 13.8478 1.191 13.5434C0.897 13.2386 0.75 12.8722 0.75 12.4444V2.33333C0.5375 2.33333 0.35925 2.25893 0.21525 2.11011C0.0717501 1.96078 0 1.77593 0 1.55556C0 1.33519 0.0717501 1.15033 0.21525 1.001C0.35925 0.852185 0.5375 0.777778 0.75 0.777778H3.75C3.75 0.557407 3.822 0.372556 3.966 0.223222C4.1095 0.0744074 4.2875 0 4.5 0H7.5C7.7125 0 7.89075 0.0744074 8.03475 0.223222C8.17825 0.372556 8.25 0.557407 8.25 0.777778H11.25C11.4625 0.777778 11.6405 0.852185 11.784 1.001C11.928 1.15033 12 1.33519 12 1.55556C12 1.77593 11.928 1.96078 11.784 2.11011C11.6405 2.25893 11.4625 2.33333 11.25 2.33333V12.4444C11.25 12.8722 11.1033 13.2386 10.8097 13.5434C10.5157 13.8478 10.1625 14 9.75 14H2.25ZM3.75 10.1111C3.75 10.3315 3.822 10.5161 3.966 10.6649C4.1095 10.8142 4.2875 10.8889 4.5 10.8889C4.7125 10.8889 4.89075 10.8142 5.03475 10.6649C5.17825 10.5161 5.25 10.3315 5.25 10.1111V4.66667C5.25 4.4463 5.17825 4.26144 5.03475 4.11211C4.89075 3.9633 4.7125 3.88889 4.5 3.88889C4.2875 3.88889 4.1095 3.9633 3.966 4.11211C3.822 4.26144 3.75 4.4463 3.75 4.66667V10.1111ZM6.75 10.1111C6.75 10.3315 6.822 10.5161 6.966 10.6649C7.1095 10.8142 7.2875 10.8889 7.5 10.8889C7.7125 10.8889 7.89075 10.8142 8.03475 10.6649C8.17825 10.5161 8.25 10.3315 8.25 10.1111V4.66667C8.25 4.4463 8.17825 4.26144 8.03475 4.11211C7.89075 3.9633 7.7125 3.88889 7.5 3.88889C7.2875 3.88889 7.1095 3.9633 6.966 4.11211C6.822 4.26144 6.75 4.4463 6.75 4.66667V10.1111Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Trash
