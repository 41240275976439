import wretch from "wretch"
import FormDataAddon from "wretch/addons/formData"
import QueryStringAddon from "wretch/addons/queryString"
import fetch from "node-fetch"

const apiKey = { "unfold-api-key": process.env.REACT_APP_UNFOLD_API_KEY }
const appKey = { "app-key": process.env.REACT_APP_MS_MECHANIX_KEY as string }

const api = wretch(`${process.env.REACT_APP_BASE_URL_API}/api/v1`)
  .headers(apiKey)
  .polyfills({
    fetch,
  })
  .addon(FormDataAddon)
  .addon(QueryStringAddon)

const msMechanixApi = wretch(`${process.env.REACT_APP_MS_MECHANIX_API}/api/v1`)
  .headers(appKey)
  .polyfills({
    fetch,
  })
  .addon(FormDataAddon)
  .addon(QueryStringAddon)

export { api, msMechanixApi }
export default api
