import { useLocation, Navigate, Outlet } from "react-router-dom"
import useAuthStore from "../store/useAuthStore"
import Layout from "./layouts/Layout"
import ConfirmContextProvider from "context/confirm-context"
import ModalUnsavedChanges from "./ModalUnsavedChanges"

function checkIf30DaysPassed(inputDate: Date | string): boolean {
  // Check if inputDate is a string, if so, convert it to a Date object
  if (typeof inputDate === "string") {
    inputDate = new Date(inputDate)
  }

  // Check if inputDate is a valid Date object
  if (!(inputDate instanceof Date && !isNaN(inputDate.getTime()))) {
    throw new Error("Invalid input date.")
  }

  // Get current date
  const currentDate = new Date()

  // Calculate the difference in milliseconds between the current date and the input date
  const differenceInMs = currentDate.getTime() - inputDate.getTime()

  // Convert milliseconds to days
  const differenceInDays = differenceInMs / (1000 * 3600 * 24)

  // Check if 30 days have passed
  return differenceInDays >= 29
}

const ProtectedRoute = () => {
  const token = useAuthStore((state) => state.token)
  const tokenCreatedAt = useAuthStore((state) => state.tokenCreatedAt)
  const reset = useAuthStore((state) => state.reset)
  const location = useLocation()

  const thirtyDaysPassed = checkIf30DaysPassed(tokenCreatedAt)

  if (thirtyDaysPassed) {
    reset()
    return <Navigate to="/" replace state={{ from: location }} />
  }

  return token ? (
    <ConfirmContextProvider>
      <Layout>
        <Outlet />
        <ModalUnsavedChanges />
      </Layout>
    </ConfirmContextProvider>
  ) : (
    <Navigate to="/" replace state={{ from: location }} />
  )
}

export default ProtectedRoute
