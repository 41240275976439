import { Listbox as ListboxPrimitive, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/24/solid"
import { Fragment } from "react"
import { useController, UseControllerProps, FieldValues } from "react-hook-form"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ListBoxProps<T> = {
  data: Array<string>
  label: string
  placeholder: string
  readOnly?: boolean
  requiered?: boolean
}
type Props<T extends FieldValues> = ListBoxProps<T> & UseControllerProps<T>

export const Listbox = <T extends FieldValues>(props: Props<T>) => {
  const { data, label, placeholder, name, control, readOnly = false, requiered = false } = props
  const {
    field: { value, onChange },
  } = useController({ name, control })

  return (
    <ListboxPrimitive
      as="div"
      value={value ?? data[0]}
      onChange={onChange}
      disabled={readOnly}
      className="peer relative"
    >
      {({ open }) => (
        <>
          <ListboxPrimitive.Label
            className={`absolute left-[14px] top-[-10px] z-[4] bg-white px-2 text-sm font-medium text-dark-gray ${
              open && "border-blue-700 text-blue-700"
            }`}
          >
            {label} {requiered && <span className="text-[#D22F27]">*</span>}
          </ListboxPrimitive.Label>
          <div className="relative">
            <ListboxPrimitive.Button
              className={`relative w-full cursor-default rounded border-[1px] border-solid 
              py-5 pl-4 pr-10 text-left text-dark-gray focus:outline-none border-[#C2C2C2]
              focus-visible:border-blue-700 
              ${open ? "border-blue-700" : "border-solid"}`}
            >
              <span className="block truncate text-xs text-dark-gray">
                {value ? (
                  <span className={`${open ? "text-blue-700" : "text-gray-900"}`}>
                    {value as string}
                  </span>
                ) : (
                  placeholder
                )}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className={`h-5 w-5 text-dark-gray 
                    ${open ? "rotate-180 transform text-blue-700" : ""}`}
                  aria-hidden="true"
                />
              </span>
            </ListboxPrimitive.Button>
            <Transition
              as={Fragment}
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxPrimitive.Options
                className="absolute z-[5] mt-1 max-h-72 w-full overflow-auto rounded-md bg-white py-1
                text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                static
              >
                {data.map((item) => (
                  <ListboxPrimitive.Option
                    key={item}
                    value={item}
                    // disabled={item.unavailable}
                    className={({ active }) =>
                      `relative cursor-default select-none px-4 py-2
                      hover:bg-purple-unfold hover:text-white ${
                        active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                      }`
                    }
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium text-gray-900 hover:text-white" : "font-normal"
                          }`}
                        >
                          {item}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center border-l-2 border-purple-unfold px-1 pl-3">
                            {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                          </span>
                        ) : null}
                      </>
                    )}
                  </ListboxPrimitive.Option>
                ))}
              </ListboxPrimitive.Options>
            </Transition>
          </div>
        </>
      )}
    </ListboxPrimitive>
  )
}
