import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import * as z from "zod"
import { api } from "../../lib/wretch"
import useAuthStore from "../../store/useAuthStore"

export default function useSignIn() {
  const token = useAuthStore((state) => state.token)
  const setToken = useAuthStore((state) => state.setToken)
  const setTokenCreatedAt = useAuthStore((state) => state.setTokenCreatedAt)
  const [error, setError] = useState<string | null>(null)

  const SignInSchema = z.object({
    email: z.string().min(1).email(),
    password: z.string().min(6).max(24),
  })

  type SignInFormValues = z.infer<typeof SignInSchema>

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<SignInFormValues>({
    resolver: zodResolver(SignInSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  })

  const onSubmit = async (data: SignInFormValues) => {
    setError(null)

    await api
      .post(data, "/user/login")
      .badRequest((err) => userNotFound(err))
      .unauthorized((err) => userNotFound(err))
      .forbidden((err) => userNotFound(err))
      .notFound((err) => userNotFound(err))
      .timeout((err) => userNotFound(err))
      .internalError((err) => userNotFound(err))
      .fetchError((err) => userNotFound(err))
      .json((response) => {
        const currentDate = new Date()
        setToken(response.token)
        setTokenCreatedAt(currentDate)
        navigate("dashboards")
      })
      .catch((err) => userNotFound(err))
  }

  function userNotFound(err: any) {
    console.log("ERROR", err)
    setError("Incorrect Email or password!")
    reset()
  }

  const navigate = useNavigate()

  useEffect(() => {
    if (token) {
      navigate("dashboards")
    }
  }, [navigate, token, errors])

  return { onSubmit, errors, isSubmitting, error, handleSubmit, register }
}
