import { Link } from "react-router-dom"
import useAuthStore from "store/useAuthStore"

function NotFound() {
  const token = useAuthStore((state) => state.token)
  return (
    <section className="bg-white">
      <div className="mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1
            className="text-primary-600 mb-4 text-7xl 
            font-extrabold tracking-tight lg:text-9xl"
          >
            404
          </h1>
          <p className="mb-4 text-3xl font-bold tracking-tight text-gray-900 md:text-4xl">
            Something's missing.
          </p>
          <p className="mb-4 text-lg font-light text-gray-500 ">Sorry, we can't find that page.</p>
          <Link to={token ? "/dashboards" : ""}>
            <button
              className="my-4 inline-flex rounded-lg bg-blue-600 px-5 py-2.5 
              text-center text-sm font-medium text-white "
            >
              Back to Homepage
            </button>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default NotFound
