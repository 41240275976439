import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline"
import { Dispatch, SetStateAction } from "react"

const range = (start: number, end: number = 5) => {
  return [...Array(end).keys()].map((el) => el + start)
}

type PaginationProps = {
  paginationCount: number
  updatePaginationCount: Dispatch<SetStateAction<number>>
  maxPaginationCount: number
}

const Pagination = ({
  paginationCount,
  updatePaginationCount,
  maxPaginationCount,
}: PaginationProps) => {
  function goToFirstPage() {
    updatePaginationCount(1)
  }

  function goToPreviousPage() {
    updatePaginationCount(paginationCount - 1)
  }

  function goToNextPage() {
    updatePaginationCount(paginationCount + 1)
  }

  function goToLastPage() {
    updatePaginationCount(maxPaginationCount)
  }

  const pages = range(1, maxPaginationCount)

  return (
    <div className="flex items-center p-4">
      <PaginationButton disabled={paginationCount === 1} onClick={goToFirstPage}>
        First
      </PaginationButton>
      <PaginationButton disabled={paginationCount === 1} onClick={goToPreviousPage}>
        <ChevronLeftIcon className="h-5 w-5" />
        Previous
      </PaginationButton>

      <ul>
        {pages.map((page) => (
          <PaginationItem
            page={page}
            key={page}
            currentPage={paginationCount}
            updatePaginationCount={updatePaginationCount}
          />
        ))}
      </ul>

      <PaginationButton disabled={paginationCount === maxPaginationCount} onClick={goToNextPage}>
        Next
        <ChevronRightIcon className="h-5 w-5" />
      </PaginationButton>
      <PaginationButton disabled={paginationCount === maxPaginationCount} onClick={goToLastPage}>
        Last
      </PaginationButton>
    </div>
  )
}

const PaginationButton = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      disabled={props.disabled}
      onClick={props.onClick}
      className="flex h-[45px] cursor-pointer items-center justify-center rounded-[50px] 
        bg-white px-4 py-1.5 text-base font-medium transition delay-300 
        duration-300 ease-in-out hover:bg-purple-unfold hover:text-white disabled:bg-white 
        disabled:opacity-75 disabled:hover:cursor-not-allowed disabled:hover:text-gray-400"
    >
      {props.children}
    </button>
  )
}

type PaginationItemProps = {
  page: number
  currentPage: number
  updatePaginationCount: Dispatch<SetStateAction<number>>
}
const PaginationItem = ({ page, currentPage, updatePaginationCount }: PaginationItemProps) => {
  function goToPage() {
    updatePaginationCount(page)
  }
  const active = page === currentPage
  return (
    <li
      value={page}
      onClick={goToPage}
      className={`bg-position-[-45px_0] mx-1.5 inline-block h-[45px] w-[45px] cursor-pointer 
      rounded-[50%] text-center font-medium leading-[45px] 
      transition delay-300 duration-300 ease-in-out 
      hover:bg-purple-unfold hover:text-white ${active && "bg-purple-unfold text-white"}`}
    >
      {page}
    </li>
  )
}

export default Pagination
