function PencilIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M11.2737 18.7891H19.729"
        stroke="currentColor"
        strokeWidth="1.69106"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5013 3.28879C15.875 2.91505 16.3819 2.70508 16.9105 2.70508C17.1722 2.70508 17.4314 2.75663 17.6731 2.85678C17.9149 2.95693 18.1346 3.10373 18.3197 3.28879C18.5048 3.47385 18.6516 3.69355 18.7517 3.93535C18.8519 4.17714 18.9034 4.43629 18.9034 4.69801C18.9034 4.95972 18.8519 5.21887 18.7517 5.46067C18.6516 5.70246 18.5048 5.92216 18.3197 6.10722L6.57626 17.8507L2.81836 18.7901L3.75783 15.0322L15.5013 3.28879Z"
        stroke="currentColor"
        strokeWidth="1.69106"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PencilIcon
