function HelpCenterIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path d="M7 0C5.61553 0 4.26215 0.410543 3.11101 1.17971C1.95987 1.94888 1.06266 3.04213 0.532846 4.32122C0.00303287 5.6003 -0.13559 7.00776 0.134506 8.36563C0.404603 9.7235 1.07129 10.9708 2.05026 11.9497C3.02922 12.9287 4.2765 13.5954 5.63437 13.8655C6.99224 14.1356 8.3997 13.997 9.67878 13.4672C10.9579 12.9373 12.0511 12.0401 12.8203 10.889C13.5895 9.73785 14 8.38447 14 7C13.9964 5.1446 13.2577 3.36623 11.9457 2.05426C10.6338 0.74229 8.8554 0.00362738 7 0ZM7 0.823529C8.12396 0.824269 9.22646 1.13135 10.189 1.71175C11.1515 2.29216 11.9376 3.12393 12.4627 4.11765H7C6.3612 4.11948 5.74092 4.33247 5.23574 4.72345C4.73057 5.11444 4.36883 5.66148 4.20686 6.27941L2.30588 2.99216C2.88483 2.31259 3.60435 1.76675 4.41479 1.39234C5.22522 1.01793 6.10726 0.82386 7 0.823529ZM9.05882 7C9.05882 7.40719 8.93808 7.80525 8.71185 8.14382C8.48562 8.48239 8.16408 8.74627 7.78788 8.9021C7.41168 9.05793 6.99772 9.0987 6.59834 9.01926C6.19897 8.93982 5.83213 8.74374 5.54419 8.45581C5.25626 8.16787 5.06018 7.80103 4.98074 7.40165C4.9013 7.00228 4.94207 6.58832 5.0979 6.21212C5.25372 5.83592 5.51761 5.51438 5.85618 5.28815C6.19475 5.06192 6.5928 4.94117 7 4.94117C7.54548 4.94298 8.0681 5.16048 8.45381 5.54619C8.83952 5.9319 9.05701 6.45452 9.05882 7ZM0.823532 7C0.823418 5.83693 1.1517 4.69747 1.77059 3.71274L4.50196 8.44117L4.51569 8.4549C4.76833 8.88971 5.13084 9.25047 5.56687 9.501C6.00289 9.75154 6.49712 9.88305 7 9.88235C7.26251 9.88438 7.52386 9.84737 7.77549 9.77255L5.87451 13.0735C4.45692 12.8081 3.17649 12.056 2.25431 10.9472C1.33213 9.83829 0.826081 8.44222 0.823532 7ZM7 13.1765H6.76667L9.49804 8.44117L9.51177 8.41372C9.82285 7.86116 9.94304 7.22143 9.85371 6.59364C9.76438 5.96585 9.47052 5.38503 9.01765 4.94117H12.8265C13.1543 5.87304 13.2541 6.86989 13.1175 7.84824C12.9809 8.8266 12.6118 9.75799 12.0413 10.5644C11.4707 11.3708 10.7153 12.0288 9.83817 12.4833C8.96107 12.9377 7.98785 13.1754 7 13.1765Z" />
    </svg>
  )
}

export default HelpCenterIcon
