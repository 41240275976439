function AcademicCap(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="21"
      height="17"
      viewBox="0 0 21 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path d="M10.5 0L0 5.66667L3.81818 7.72556V13.3922L10.5 17L17.1818 13.3922V7.72556L19.0909 6.69611V13.2222H21V5.66667L10.5 0ZM17.01 5.66667L10.5 9.18L3.99 5.66667L10.5 2.15333L17.01 5.66667ZM15.2727 12.2778L10.5 14.8467L5.72727 12.2778V8.755L10.5 11.3333L15.2727 8.755V12.2778Z" />
    </svg>
  )
}

export default AcademicCap
