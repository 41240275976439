function Pencil(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path d="M0.95288 8.14712H1.6199L5.72919 4.03783L5.06217 3.37081L0.95288 7.48011V8.14712ZM7.76597 3.34699L5.7411 1.34594L7.08704 0L9.1 2.01296L7.76597 3.34699ZM0 9.1V7.07513L5.05026 2.02487L7.07513 4.04974L2.02487 9.1H0ZM5.39568 3.70432L5.06217 3.37081L5.72919 4.03783L5.39568 3.70432Z" />
    </svg>
  )
}

export default Pencil
