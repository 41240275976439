function ClipboardCheck(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path d="M12.4444 1.6H9.19333C8.86667 0.672 8.01111 0 7 0C5.98889 0 5.13333 0.672 4.80667 1.6H1.55556C1.143 1.6 0.747335 1.76857 0.455612 2.06863C0.163888 2.36869 0 2.77565 0 3.2V14.4C0 14.8243 0.163888 15.2313 0.455612 15.5314C0.747335 15.8314 1.143 16 1.55556 16H12.4444C12.857 16 13.2527 15.8314 13.5444 15.5314C13.8361 15.2313 14 14.8243 14 14.4V3.2C14 2.77565 13.8361 2.36869 13.5444 2.06863C13.2527 1.76857 12.857 1.6 12.4444 1.6ZM7 1.6C7.20628 1.6 7.40411 1.68429 7.54997 1.83431C7.69583 1.98434 7.77778 2.18783 7.77778 2.4C7.77778 2.61217 7.69583 2.81566 7.54997 2.96569C7.40411 3.11571 7.20628 3.2 7 3.2C6.79372 3.2 6.59589 3.11571 6.45003 2.96569C6.30417 2.81566 6.22222 2.61217 6.22222 2.4C6.22222 2.18783 6.30417 1.98434 6.45003 1.83431C6.59589 1.68429 6.79372 1.6 7 1.6ZM3.11111 4.8H10.8889V3.2H12.4444V14.4H1.55556V3.2H3.11111V4.8ZM3.5 10L4.66667 8.8L6.22222 10.4L9.72222 6.8L10.8889 8L6.22222 12.8L3.5 10Z" />
    </svg>
  )
}

export default ClipboardCheck
