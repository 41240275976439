function UsersIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path d="M8.00008 11.833C8.50425 11.833 8.936 11.6533 9.29533 11.294C9.65405 10.9353 9.83342 10.5038 9.83342 9.99967C9.83342 9.49551 9.65405 9.06376 9.29533 8.70442C8.936 8.3457 8.50425 8.16634 8.00008 8.16634C7.49591 8.16634 7.06447 8.3457 6.70575 8.70442C6.34642 9.06376 6.16675 9.49551 6.16675 9.99967C6.16675 10.5038 6.34642 10.9353 6.70575 11.294C7.06447 11.6533 7.49591 11.833 8.00008 11.833ZM4.33341 15.4997H11.6667V14.9726C11.6667 14.6059 11.5674 14.2698 11.3688 13.9643C11.1702 13.6587 10.8952 13.4295 10.5438 13.2768C10.1466 13.1087 9.73808 12.9788 9.31825 12.8872C8.8978 12.7955 8.45842 12.7497 8.00008 12.7497C7.54175 12.7497 7.10236 12.7955 6.68192 12.8872C6.26208 12.9788 5.85355 13.1087 5.45633 13.2768C5.10494 13.4295 4.82994 13.6587 4.63133 13.9643C4.43272 14.2698 4.33341 14.6059 4.33341 14.9726V15.4997ZM13.5001 19.1663H2.50008C1.99591 19.1663 1.56447 18.987 1.20575 18.6283C0.846415 18.2689 0.666748 17.8372 0.666748 17.333V2.66634C0.666748 2.16217 0.846415 1.73042 1.20575 1.37109C1.56447 1.01237 1.99591 0.833008 2.50008 0.833008H9.07716C9.32161 0.833008 9.55475 0.878841 9.77658 0.970508C9.9978 1.06217 10.1924 1.19204 10.3605 1.36009L14.8063 5.80592C14.9744 5.97398 15.1042 6.16862 15.1959 6.38984C15.2876 6.61167 15.3334 6.84481 15.3334 7.08926V17.333C15.3334 17.8372 15.1541 18.2689 14.7953 18.6283C14.436 18.987 14.0042 19.1663 13.5001 19.1663Z" />
    </svg>
  )
}

export default UsersIcon
