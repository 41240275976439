function Analytics(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="11"
      viewBox="0 0 20 11"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path d="M18.0834 1.24785e-07C17.7855 9.90722e-05 17.4918 0.0717856 17.2255 0.209368C16.9593 0.34695 16.7278 0.54664 16.5495 0.792582C16.3711 1.03852 16.2508 1.32395 16.1982 1.62619C16.1455 1.92843 16.1619 2.23916 16.2461 2.53372L13.2734 5.59824C12.8814 5.46359 12.4576 5.46359 12.0656 5.59824L9.86178 3.32605C9.93429 3.03429 9.94115 2.7293 9.88181 2.43439C9.82248 2.13948 9.69853 1.86247 9.51943 1.62452C9.34034 1.38658 9.11085 1.19401 8.8485 1.06154C8.58616 0.929065 8.29791 0.860196 8.00579 0.860196C7.71367 0.860196 7.42542 0.929065 7.16307 1.06154C6.90073 1.19401 6.67124 1.38658 6.49214 1.62452C6.31305 1.86247 6.1891 2.13948 6.12976 2.43439C6.07043 2.7293 6.07728 3.03429 6.1498 3.32605L2.46406 7.12362C2.05487 6.99874 1.61697 7.01802 1.21959 7.17843C0.822213 7.33883 0.488018 7.63122 0.26983 8.00935C0.0516417 8.38749 -0.0380941 8.82982 0.0148064 9.26642C0.0677068 9.70302 0.260226 10.109 0.561934 10.4202C0.863642 10.7313 1.25733 10.93 1.68077 10.9847C2.10421 11.0393 2.53325 10.947 2.90007 10.7221C3.2669 10.4973 3.55058 10.1528 3.70628 9.74314C3.86199 9.33348 3.88083 8.88199 3.75984 8.46005L7.39893 4.70843C7.79095 4.84317 8.21479 4.84317 8.60682 4.70843L10.811 6.98105C10.7385 7.2728 10.7316 7.5778 10.791 7.8727C10.8503 8.16761 10.9743 8.44463 11.1534 8.68257C11.3324 8.92051 11.5619 9.11308 11.8243 9.24555C12.0866 9.37803 12.3749 9.4469 12.667 9.4469C12.9591 9.4469 13.2474 9.37803 13.5097 9.24555C13.7721 9.11308 14.0016 8.92051 14.1806 8.68257C14.3597 8.44463 14.4837 8.16761 14.543 7.8727C14.6024 7.5778 14.5955 7.2728 14.523 6.98105L17.5419 3.87015C17.8054 3.95032 18.0826 3.9711 18.3547 3.93108C18.6267 3.89107 18.8872 3.79119 19.1185 3.63823C19.3498 3.48526 19.5464 3.2828 19.6952 3.04456C19.8439 2.80633 19.9411 2.5379 19.9804 2.25749C20.0196 1.97708 19.9999 1.69125 19.9225 1.41939C19.8452 1.14753 19.712 0.896008 19.5321 0.681885C19.3522 0.467761 19.1297 0.296047 18.8797 0.178394C18.6297 0.06074 18.3581 -0.000100569 18.0834 1.24785e-07Z" />
    </svg>
  )
}

export function AnalyticsSmall(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M11.25 0H9.75C9.55109 0 9.36032 0.0761249 9.21967 0.211628C9.07902 0.347131 9 0.530913 9 0.722543V13H12V0.722543C12 0.530913 11.921 0.347131 11.7803 0.211628C11.6397 0.0761249 11.4489 0 11.25 0ZM6.75 4.33526H5.25C5.05109 4.33526 4.86032 4.41139 4.71967 4.54689C4.57902 4.68239 4.5 4.86617 4.5 5.0578V13H7.5V5.0578C7.5 4.86617 7.42098 4.68239 7.28033 4.54689C7.13968 4.41139 6.94891 4.33526 6.75 4.33526ZM2.25 8.67052H0.75C0.551088 8.67052 0.360322 8.74664 0.21967 8.88215C0.0790176 9.01765 0 9.20143 0 9.39306V13H3V9.39306C3 9.20143 2.92098 9.01765 2.78033 8.88215C2.63968 8.74664 2.44891 8.67052 2.25 8.67052Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Analytics
