import { Listbox, Transition } from "@headlessui/react"
import { StopIcon } from "@heroicons/react/24/outline"
import { ChevronDownIcon, StopIcon as StopSolid, XMarkIcon } from "@heroicons/react/24/solid"
import { useEffect, useState } from "react"
import { useController, UseControllerProps } from "react-hook-form"
import { LeadsFormValues } from "../../types/Leads"

type MultiSelectProps = {
  data?: Array<string>
  label?: string
  placeholder?: string
  readOnly?: boolean
}
type FormValues = LeadsFormValues
type Props = MultiSelectProps & UseControllerProps<FormValues>

const services = ["Mobile App", "Website", "Branding", "Development", "How to"]

export default function MultiSelect(props: Props) {
  const { data = services, label, name, control, readOnly = false } = props
  const {
    field: { value, onChange },
  } = useController({ name, control })
  const [selectedPersons, setSelectedPersons] = useState<Array<string> | undefined>([])

  function isSelected(selectedString: string) {
    return selectedPersons?.find((el) => el === selectedString) ? true : false
  }

  function handleSelection(person: any) {
    let customEvent = {}

    const selectedResult = selectedPersons?.filter((selected) => selected === person)

    if (selectedResult?.length) {
      removePerson(person)
    } else {
      setSelectedPersons((currents) => [...(currents as string[]), person])
      customEvent = {
        target: {
          value: [...(selectedPersons as string[]), person],
          name: name,
        },
        type: "change",
      }
    }

    onChange(customEvent)
  }

  function removePerson(person: string) {
    const removedSelection = selectedPersons?.filter((selected) => selected !== person)

    setSelectedPersons(removedSelection)

    const customEvent = {
      target: {
        value: removedSelection,
        name: name,
      },
      type: "change",
    }

    onChange(customEvent)
  }

  useEffect(() => {
    let ignore = false
    if (value && !ignore) {
      setSelectedPersons(value as string[])
    }
    return () => {
      ignore = true
    }
  }, [value])

  return (
    <Listbox
      as="div"
      className="relative space-y-1"
      disabled={readOnly}
      value={selectedPersons}
      onChange={handleSelection}
    >
      {({ open }) => (
        <>
          <Listbox.Label
            className="absolute top-[-7px] left-[14px] z-[4] bg-white px-2 text-sm 
            text-dark-gray"
          >
            Service
          </Listbox.Label>
          <div className="relative">
            <span className="inline-block w-full rounded-md shadow-sm">
              <Listbox.Button
                className="focus:shadow-outline-blue relative w-full cursor-default rounded-md
                  border border-solid border-dark-gray bg-white py-2.5 pl-3 pr-10 text-left transition
                  duration-150 ease-in-out focus:outline-none sm:text-sm sm:leading-5"
              >
                {selectedPersons?.length ? (
                  selectedPersons?.map((person) => (
                    <div
                      key={person}
                      className="my-0.5 mr-1 inline-flex items-center rounded border border-dark-gray 
                      bg-white px-1 text-gray-900"
                    >
                      {person}
                      {!readOnly && (
                        <div
                          className="ml-1 cursor-pointer rounded-full bg-gray-50"
                          onClick={() => removePerson(person)}
                        >
                          <XMarkIcon className="h-5 w-5 text-dark-gray" />
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <span className="text-xs text-dark-gray">{label ?? "Select your Service"}</span>
                )}
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    className={`h-5 w-5 text-dark-gray 
                    ${open ? "rotate-180 transform" : ""}`}
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
            </span>

            <Transition
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="mt-1"
            >
              <Listbox.Options
                static
                className="absolute z-[5] max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base 
                leading-6 shadow-md focus:outline-none sm:text-sm sm:leading-5"
              >
                {data.map((service) => {
                  const selected = isSelected(service)
                  return (
                    <Listbox.Option key={service} value={service} disabled={selected}>
                      {({ active }) => (
                        <div
                          className={`${
                            active ? "bg-blue-600 text-white" : "text-gray-900"
                          }  flex cursor-default select-none items-center py-2 pl-2 pr-4`}
                        >
                          {selected ? (
                            <StopSolid
                              className="h-8 w-8 items-center pl-2 
                                text-primary"
                            />
                          ) : (
                            <StopIcon
                              className="h-8 w-8 items-center pl-2 
                                text-primary"
                            />
                          )}
                          <span
                            className={`${
                              selected ? "font-semibold" : "font-normal"
                            } block truncate pl-1`}
                          >
                            {service}
                          </span>
                        </div>
                      )}
                    </Listbox.Option>
                  )
                })}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
