import * as z from "zod"

export const LeadsBulkSchema = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  email: z.string().optional(),
  phoneNumber: z.string().optional(),
  city: z.string().optional(),
  country: z.string().optional(),
  businessType: z.string().optional(),
  service: z.string().array().optional(),
  status: z.string().optional(),
  statusReason: z.string().optional(),
  createdBy: z.string().optional(),
  modifiedBy: z.string().optional(),
  createdOn: z.string().optional(),
  modifiedOn: z.string().optional(),
  _id: z.string().optional(),
})

export const LeadsSchema = z.object({
  firstName: z.string().min(2).max(24).optional(),
  lastName: z.string().min(2).max(24).optional(),
  email: z.string().min(2).email().optional(),
  phoneNumber: z.string().min(2).max(20).optional(),
  city: z.string().min(2).optional(),
  country: z.string().min(2).optional(),
  businessType: z.string().min(1).optional(),
  service: z.string().array().min(1).max(5).optional(),
  status: z.string().optional(),
  statusReason: z.string().optional(),
  origin: z.string().optional(),
  subject: z.string().optional(),
  message: z.string().optional(),
  resume: z.any().optional(),
  createdBy: z.string().optional(),
  modifiedBy: z.string().optional(),
  createdOn: z.string().optional(),
  modifiedOn: z.string().optional(),
  _id: z.string().optional(),
})

export type LeadsFormValues = z.infer<typeof LeadsSchema>

export type TLeadsPaginatedData = {
  status: string
  leadsCount: number
  currentPage: number
  totalPages: number
  data: TData
}

export type TLeadsSingleData = {
  status: string
  data: {
    lead: TLead
  }
}

export type TData = {
  leads: TLead[]
}

export type TLead = {
  _id: string
  firstName: string
  lastName: string
  fullName: string
  email: string
  phoneNumber: string
  city: string
  country: string
  businessType: string
  service: string[]
  status: string
  createdOn: Date | string
  createdBy: string
  __v: number
  statusReason?: string
}
