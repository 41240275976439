import { ErrorMessage } from "@hookform/error-message"
import { FiFacebook, FiGithub, FiTwitter } from "react-icons/fi"
import { Link } from "react-router-dom"
import FormErrorMessage from "../../components/FormErrorMessage"
import useSignIn from "./useSignIn"

function SignIn() {
  const { onSubmit, errors, isSubmitting, error, handleSubmit, register } = useSignIn()

  return (
    <div className="flex h-screen  flex-row">
      <section className="flex w-full flex-col items-center px-16  py-8 md:w-[45%]">
        <div
          className="mx-auto flex h-full w-full max-w-[32rem] flex-col  px-16 py-8
        sm:mx-0 sm:w-[32rem]"
        >
          <HeaderSignIn />
          <form onSubmit={handleSubmit(onSubmit)} className="mt-8 flex flex-col gap-y-8">
            <div className="relative">
              <input
                id="email"
                type="text"
                placeholder="Enter your Email"
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: "Please enter a valid email",
                  },
                })}
                className={`border-1 peer w-full rounded border-solid outline-none 
                 placeholder:text-xs focus:border-solid focus:border-blue-700 focus:ring-blue-700
                 ${
                   errors.email
                     ? "border-red-500 invalid:border-red-500 focus:border-red-500 focus:ring-red-500"
                     : "border-gray-300"
                 }`}
              />
              <label
                htmlFor="email"
                className="absolute top-[-10px] left-[14px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
              >
                Email
              </label>
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </div>
            <div className="relative">
              <input
                id="password"
                type="password"
                placeholder="Enter your Password"
                {...register("password", { required: true, max: 24, min: 8 })}
                className={`border-1 peer w-full rounded border-solid outline-none 
                 placeholder:text-xs focus:border-solid focus:border-blue-700 focus:ring-blue-700
                 ${
                   errors.password
                     ? "border-red-500 invalid:border-red-500 focus:border-red-500 focus:ring-red-500"
                     : "border-gray-300"
                 }`}
              />
              <label
                htmlFor="password"
                className="absolute top-[-10px] left-[14px] bg-white px-2 text-sm text-dark-gray peer-focus:text-blue-700"
              >
                Password
              </label>
              <ErrorMessage
                errors={errors}
                name="password"
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="checkbox-2"
                  type="checkbox"
                  checked
                  readOnly
                  className="h-4 w-4 rounded border-gray-300 bg-gray-100 
                  text-blue-600 focus:ring-2 focus:ring-blue-500"
                />
                <label htmlFor="checkbox-2" className="ml-2 text-sm font-medium text-gray-900">
                  Remember me
                </label>
              </div>

              <div>
                <Link className="text-md font-medium" to="/">
                  Forgot password?
                </Link>
              </div>
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="mb-2 h-12 rounded-full bg-[#4F46E5] px-5
              py-2.5 text-center text-sm font-medium text-white hover:bg-indigo-800 
              focus:outline-none focus:ring-1 focus:ring-indigo-300"
            >
              {isSubmitting ? <IsSubmitting /> : "Sign In"}
            </button>
            {error && <FormErrorMessage>{error}</FormErrorMessage>}
            <div className="flex items-center py-4">
              <div className="h-px flex-grow bg-gray-400"></div>
              <span className="text-md flex-shrink px-4 font-light text-gray-500">
                or continue with
              </span>
              <div className="h-px flex-grow bg-gray-400"></div>
            </div>

            <div className="flex items-center space-x-16">
              <button
                className="flex flex-auto flex-col items-center rounded-3xl border 
                border-gray-800 px-6 py-2"
                name="facebook"
              >
                <FiFacebook size={20} />
              </button>
              <button
                className="flex flex-auto flex-col items-center rounded-3xl border 
                border-gray-800 px-6 py-2"
                name="twitter"
              >
                <FiTwitter size={20} />
              </button>
              <button
                className="flex flex-auto flex-col items-center rounded-3xl border 
                border-gray-800 px-6 py-2"
                name="github"
              >
                <FiGithub size={20} />
              </button>
            </div>
          </form>
        </div>
      </section>
      <RightSide />
    </div>
  )
}

function HeaderSignIn() {
  return (
    <>
      <img className="mb-4 w-32" src="assets/images/logo/unfold-3.png" alt="logo unfold" />
      <h1 className="text-4xl font-extrabold leading-tight tracking-tight">Sign in</h1>
      <div className="mt-2 flex items-baseline font-normal">
        <p>Don't have an account?</p>
        <Link className="ml-4 font-medium text-blue-700 underline" to="">
          Sign up
        </Link>
      </div>
    </>
  )
}

function RightSide() {
  return (
    <section
      className="relative hidden h-full flex-auto items-center justify-center 
        overflow-hidden bg-gray-800 p-[6.4rem] md:flex lg:px-[11.2rem]"
    >
      <svg
        className="pointer-events-none absolute inset-0 text-white"
        viewBox="0 0 960 540"
        width="100%"
        height="100%"
        preserveAspectRatio="xMidYMax slice"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className="opacity-20" fill="none" stroke="currentColor" strokeWidth="100">
          <circle r="234" cx="196" cy="23" />
          <circle r="234" cx="790" cy="491" />
        </g>
      </svg>
      <svg
        className="absolute -top-64 -right-64 opacity-20"
        viewBox="0 0 220 192"
        width="220px"
        height="192px"
        fill="none"
      >
        <defs>
          <pattern
            id="837c3e70-6c3a-44e6-8854-cc48c737b659"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect x="0" y="0" width="4" height="4" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
      </svg>
      <div className="relative z-10 w-full">
        <div className="text-5xl font-bold leading-none text-gray-100">
          <div>Welcome to</div>
          <div>our community</div>
        </div>
        <div className="mt-[2.4rem] text-lg leading-6 tracking-tight text-gray-400">
          Fuse helps developers to build organized and well coded dashboards full of beautiful and
          rich modules. Join us and start building your application today.
        </div>
        <div className="mt-32 flex items-center">
          <img src="/assets/avatars/avatars.png" alt="avatars" />

          <div className="ml-4 font-medium tracking-tight text-gray-400">
            More than 17k people joined us, it's your turn
          </div>
        </div>
      </div>
    </section>
  )
}

function IsSubmitting() {
  return (
    <div className="flex items-center justify-center gap-2">
      <div className="w-fit">
        <svg
          aria-hidden="true"
          className="mr-2 h-8 w-8 animate-spin fill-[#4F46E5] text-gray-200"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
      </div>
      <span className="w-fit">Sending...</span>
    </div>
  )
}

export default SignIn
